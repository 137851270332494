import React from "react";
import { Layout, notification, Divider } from "antd";
import { MailOutlined } from "@ant-design/icons";

const openNotification = () => {
  const args = {
    message: "Get In Touch",
    description: (
      <div>
        <Divider />
        <a href={"mailto:" + toSales + "@" + domain}>Contact Sales</a>
        <Divider />
        <a href={"mailto:" + toSupport + "@" + domain}>Contact Support</a>
        <Divider />
        <a href={"mailto:" + toInfo + "@" + domain}>Contact Info</a>
        <Divider />
      </div>
    ),
    icon: <MailOutlined />,
    duration: 0,
  };
  notification.open(args);
};

const toSupport = "support";
const toSales = "sales";
const toInfo = "info";
const domain = "dayviewer.com";

class ContactUs extends React.Component {
  render() {
    return <a onClick={openNotification}>Contact</a>;
  }
}

export default ContactUs;
